<template>
  <div class="component-wrap">
    <div class="component-tit">
      <RollbackOutlined class="back" @click="back" />{{ $t("bbs.my.like") }}
      <!-- 点赞我的 -->
    </div>
    <div class="content">
      <a-spin :spinning="loading">
        <div
          class="list"
          v-infinite-scroll="handleInfiniteOnLoad"
          :infinite-scroll-immediate-check="false"
          :infinite-scroll-disabled="scrollDisabled"
          infinite-scroll-watch-disabled="scrollDisabled"
          :infinite-scroll-distance="50"
        >
          <div
            class="list-item"
            v-for="(item, index) in subjectList"
            :key="index"
          >
            <div class="posts">
              <div class="posts-head">
                <div class="avatar">
                  <a-avatar
                    :size="50"
                    :src="
                      item.likePortrait ||
                      require(`@/assets/image/bbs/avatar.png`)
                    "
                  >
                    <OpenData type="userName" :openid="item.likeUser" />
                  </a-avatar>
                </div>
                <div class="text">
                  <div class="name">
                    <OpenData type="userName" :openid="item.likeUser" />
                    <div
                      class="comment-medal"
                      v-if="
                        item.mediaImage && companyInfo.menu.includes('medal')
                      "
                    >
                      <div class="medal-name">
                        <img
                          draggable="false"
                          :src="item.mediaImage"
                          alt=""
                        />
                        {{ item.medalName }}
                      </div>
                    </div>
                    <span class="department">
                      <OpenData
                        type="departmentName"
                        :openid="item.likeDepartment"
                      />
                    </span>
                  </div>
                  <div class="info">
                    <!-- 赞了   这个-->
                    <span>{{ $t("bbs.liked_2") }}</span>
                    {{ $t("bbs.this") }}
                    <template v-if="item.subjectType === 1"
                      >{{
                        item.commentId ? $t("LB_Doc_Comments") : $t("bbs.post")
                      }}
                    </template>
                    <!-- "评论" : "帖子" -->
                    <template v-if="item.subjectType === 2">{{
                      item.commentId ? $t("CM_QAAnswer") : $t("MN_QA_MHome")
                    }}</template>
                    <!-- "回答" : "问答" -->
                    <template v-if="item.subjectType === 3">{{
                      item.commentId ? $t("LB_Doc_Comments") : $t("bbs.article")
                    }}</template>
                    <!-- "评论" : "文章" -->
                  </div>
                </div>
              </div>
              <div class="posts-time">{{ dateFormat(item.likeTime) }}</div>
              <div
                class="posts-reference"
                v-if="item.commentId"
                @click="subjectJump(item)"
              >
                <div
                  class="cover"
                  v-if="item.myImageList && item.myImageList.length"
                >
                  <img :src="item.myImageList[0].filePath" alt="" />
                </div>
                <div class="posts-reference-text">
                  {{ item.commentContent }}
                </div>
              </div>
              <div class="posts-reference" v-else @click="subjectJump(item)">
                <div
                  class="cover"
                  v-if="item.coverImgss && item.coverImgss.length"
                >
                  <img :src="item.coverImgss[0]" alt="" />
                </div>
                <div
                  class="cover"
                  v-else-if="item.coverVideoss && item.coverVideoss.length"
                >
                  <video
                    :src="
                      (
                        item.coverVideoss[0].filePath.slice(
                          0,
                          item.coverVideoss[0].filePath.lastIndexOf('.')
                        ) + '/1/mp4/1_1.mp4'
                      ).replace('/source/', '/')
                    "
                  />
                  <div class="mask">
                    <PlayCircleFilled class="play" />
                  </div>
                </div>
                <div class="posts-reference-text">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <a-empty
            v-if="!subjectList.length && !loading"
            style="padding: 50px 0"
          />
        </div>
      </a-spin>
      <div class="load-more" v-if="subjectList.length && !loading">
        {{ scrollDisabled ? $t("bbs.no_more") : $t("LB_Doc_Mobile_Load") }}
        <!-- "已经到底了" : "加载中..." -->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { dateFormat } from "@/utils/tools";
import checkJump from "@/views/bbs/utils/checkJump";

import { getLikeForMeList } from "@/api/bbs";

import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      newWindow: computed(() => store.getters.companyInfo.useNewWindow == 1),
      loading: false,
      scrollDisabled: true,
      subjectParams: {
        pageSize: 10,
        page: 1,
      },
      subjectList: [],
    });
    const companyInfo = computed(() => store.getters.companyInfo);

    const getSubject = () => {
      if (state.subjectParams.page === 1) {
        state.loading = true;
      }
      state.scrollDisabled = true;
      getLikeForMeList(state.subjectParams).then((res) => {
        state.loading = false;
        let list = res.data.list || [];
        state.subjectList.push(...list);
        if (state.subjectList.length < res.data.totals) {
          nextTick(() => {
            state.scrollDisabled = false;
          });
        } else {
          state.scrollDisabled = true;
        }
      });
    };
    getSubject();

    const handleInfiniteOnLoad = () => {
      if (!state.scrollDisabled) {
        state.subjectParams.page++;
        getSubject();
      }
    };

    const subjectJump = (item) => {
      checkJump(state.newWindow, item);
    };

    const back = () => {
      if (state.newWindow) {
        window.location.href = "/bbs";
      } else {
        router.push("/bbs");
      }
    };

    return {
      dateFormat,
      ...toRefs(state),
      companyInfo,
      handleInfiniteOnLoad,
      subjectJump,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  .component-tit {
    font-size: 20px;
    line-height: 54px;
    color: #202020;
    padding: 0 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .back {
      margin-right: 10px;
      color: #999;
      cursor: pointer;
    }
  }
  .content {
    padding: 0 30px;
    background-color: #fff;
    border-radius: 2px;
    .list {
      min-height: 200px;
      &-item {
        padding: 30px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
    .load-more {
      text-align: center;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding: 24px 0;
    }
  }
}
.posts {
  &-head {
    .mixinFlex(space-between);
    margin-bottom: 8px;
    .avatar {
      width: 50px;
    }
    .text {
      width: calc(100% - 50px);
      padding-left: 14px;
      font-size: 14px;
      .mixinFlex(center; flex-start; column);
      .name {
        color: #202020;
        line-height: 22px;
        .department {
          padding-left: 8px;
          font-size: 12px;
          color: #999999;
        }
      }
      .info {
        font-size: 14px;
        line-height: 22px;
        color: #333;
        span {
          color: @color-theme;
        }
      }
    }
  }
  &-time {
    color: #7a859b;
    font-size: 14px;
    line-height: 22px;
    margin: 12px 0 14px 64px;
  }
  &-reference {
    margin-left: 64px;
    background-color: #fafafa;
    padding: 20px;
    border-radius: 4px;
    cursor: pointer;
    .mixinFlex(space-between);
    .cover {
      width: 120px;
      height: 70px;
      margin-right: 16px;
      background-color: #f5f5f5;
      flex: none;
      position: relative;
      cursor: pointer;
      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.1);
        .mixinFlex(center; center);
        .play {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    &-text {
      min-width: 726px;
      word-break: break-all;
      font-size: 14px;
      color: #666;
      .mixinEllipsis(70px, 3);
      min-height: 23px;
    }
  }
}
.comment-medal {
  padding-left: 13px;
  margin-left: 5px;
  .mixinFlex(flex-start, center);
  display: inline-flex;
  .medal-name {
    display: inline-block;
    min-width: 52px;
    height: 16px;
    background: linear-gradient(
      90deg,
      rgba(252, 106, 43, 1) 0%,
      rgba(222, 205, 58, 1) 100%
    );
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 0 8px 0 14px;
    border-radius: 0 8px 8px 0;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: -13px;
      top: -4px;
    }
  }
}
</style>
